var site = site || {};

// @todo find a way to share this js between cross sell, tabbed products and trends
(function ($) {
  Drupal.behaviors.tabbedProductsBlockV1 = {
    attach: function (context) {
      var $modules = $('.tabbed-products-block-v1', context);
      var $grids = $('.js-product-grid', $modules);
      var $carousels = $('.js-product-brief-carousel-items', $grids);

      $modules.each(function () {
        var $module = $(this);
        var $trigger = $('.js-tabbed-products-block-trigger', $module);
        var $content = $('.js-tabbed-products-block-content', $module);

        $trigger.off('click.tabbedBlock').on('click.tabbedBlock', function (event) {
          event.preventDefault();
          var index = $(this).index();

          switchToIndex(index);
        });

        function switchToIndex(index) {
          var $activeContent = $content.eq(index);

          // Show this tab content
          $content.removeClass('current');
          $activeContent.addClass('current');
          $trigger.removeClass('current');
          $trigger.eq(index).addClass('current');
        }
      });
      $carousels.each(function () {
        var $this = $(this);
        var $arrowsDiv = $this.parent().find('.carousel-controls');
        var slides_to_show = JSON.parse($this.closest('.js-product-grid').attr('data-grid-items-per-row'));
        var isRTL = site.direction.isRTL && Drupal?.settings?.forceSlickRTL;
        var settings = {
          appendArrows: $arrowsDiv,
          rtl: isRTL,
          infinite: true,
          slidesToShow: slides_to_show.large,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                arrows: true,
                dots: false,
                slidesToShow: slides_to_show.medium,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 769,
              settings: {
                arrows: true,
                dots: false,
                slidesToShow: slides_to_show.small,
                slidesToScroll: 1
              }
            }
          ]
        };

        // Init this carousel with our settings
        $this.slick(settings);
      });
    }
  };
})(jQuery);
